import { ShadowBox } from '../components/ShadowBox'
import { Input } from '../components/atom/Input'
import { useForm, Controller } from 'react-hook-form'

import React, {
  ButtonHTMLAttributes,
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import useUserStore from '../store/useUserStore'
import usePostList from '../hooks/query/userPostList'
import { Modal } from '../components/layout/Modal'

import { useLocation, useNavigate } from 'react-router-dom'
import { useGetProducts } from '../hooks/query/useGetProducts'
import { CircularCheckbox } from '../components/CircularCheckbox'
import '../App.css'
import useGetBgm from '../hooks/query/useGetBgm'

import useGetVoice from '../hooks/query/useGetVoice'
import { useGetShortTypeTone } from '../hooks/query/useGetTone'
import useGetPrompt from '../hooks/query/useGetPrompt'
import TooltipIcon from '../components/TooltipIcon'
import { Usage } from '../service/user'

import {
  getLocalStorage,
  getUtmLocalStorage,
  LOCAL_THUMBNAIL_INFO,
  removeLocalStorage,
  saveLocalStorage,
  UserType,
} from '../utils/common'

import { CSSTransition } from 'react-transition-group'
import { GuideToUse } from '../components/GuideToUse'

import ContentTooltip from '../components/shortvideo/ContentToolitip'
import ShortVideoTitleTooltip from '../components/shortvideo/ShortVideoTitleTooltip'
import AnimationTitleTooltip from '../components/shortvideo/AnimationTitleTooltip'
import useGetAnimation from '../hooks/query/useGetAnimation'
import useGetBg from '../hooks/query/useGetBg'
import BgTooltip from '../components/shortvideo/BgToolitip'
import VoiceTooltip from '../components/shortvideo/VoiceTooltip'
import BgmTooltip from '../components/shortvideo/BgmTooltip'

import CashUseModal from '../components/CashUseModal'
import { useGetMyPoint } from '../hooks/query/point/useGetMyPoint'
import { getShortImageList, ShortVideoInfo } from '../service/list'

import useConfirmPoint from '../hooks/query/point/useConfirmPoint'
import { Spinner } from '../components/atom/Spinner'
import ConfirmPaymentCashModal from '../components/ConfirmPaymentCashModal'
import useGetPromptDuration from '../hooks/query/useGetPromptDuration'
import DurationToolitip from '../components/shortvideo/DurationToolitip'
import classNames from 'classnames'
import { ImageTypeModal } from '../components/ImageTypeModal'
import { MixTypeModal } from '../components/MixTypeModal'
import { VideoTypeModal } from '../components/VideoTypeModal'
import useGetTemplate from '../hooks/query/useGetTemplate'
import { isMobile } from 'react-device-detect'
import useGetSubtitle from '../hooks/query/useGetSubtitle'
import SubtitleTolltip from '../components/shortvideo/SubtitleTooltip'
import SubtitleToolltip from '../components/shortvideo/SubtitleTooltip'
import AiToneTooltip from '../components/AiToneTooltip'
import { pointPurchaseBtnClick, purchasePoint } from '../utils/metaAd'
import { useSaveUtm } from '../hooks/query/useSaveUtm'

export const ShortVideo = () => {
  const urlParams = new URLSearchParams(window.location.search)

  const paymentKey = urlParams.get('paymentKey')
  const orderId = urlParams.get('orderId')
  const amount = urlParams.get('amount')
  const bonus = urlParams.get('bonus')
  const pType = urlParams.get('pType')
  const mType = urlParams.get('mType')
  const memory = urlParams.get('m')
  const promotionCode = urlParams.get('promotionCode')
  const valid = urlParams.get('valid')
  // bgm / voice / transition 불러오기
  const URL_PATTERN = /(?:news|entertain|sports|blog)\.naver\.com|naver\.me/
  const PRODUCT_NAME_PATTERN = /^[^<>\uD800-\uDFFF]*$/

  const shortVideoData = getLocalStorage('shortVideoData')

  const { mutate: saveUtmMutate } = useSaveUtm()
  const { data: myPointData, refetch: getMyPointRefetch } = useGetMyPoint()
  const { data: bgmList } = useGetBgm()
  const { data: subTitleList } = useGetSubtitle()
  const { data: voiceList } = useGetVoice()
  const { data: toneList } = useGetShortTypeTone('short')
  const { data: animationList } = useGetAnimation()
  const { data: aiPromptDurationList } = useGetPromptDuration()
  const { data: bgList } = useGetBg()
  const { data: templateList } = useGetTemplate()
  const { mutate: confirmPointMutate, isPending } = useConfirmPoint()
  const navigate = useNavigate()
  const userInfo = useUserStore((state: any) => state.userInfo)

  const userCheck = () => {
    if (!userInfo) {
      navigate('/sign-up')
      return
    }
  }
  const location = useLocation()
  const { pathname } = useLocation()
  const [isCrawlingLoading, setCrawlingLoading] = useState<boolean>(false)
  const [isSuccess, setSuccess] = useState<boolean>(false)
  const [isRequest, setRequest] = useState<boolean>(true)
  const [saveSuccessModal, setSaveSuccessModal] = useState<boolean>(false)
  const [essentialCheck, setEssentialCheck] = useState<boolean>(false)
  const [isUseModal, setUseModal] = useState<boolean>(false)
  const [isModal, setModal] = useState<boolean>(false)
  const [isImageTypeModal, setImageTypeModal] = useState<boolean>(false)
  const [isMixTypeModal, setMixTypeModal] = useState<boolean>(false)
  const [isVideoTypeModal, setVideoTypeModal] = useState<boolean>(false)
  const [hasLinkError, setHasLinkError] = useState<boolean>(false)

  const [amountValue, setAmountValue] = useState(0)
  const [bonusValue, setBonusValue] = useState(0)
  const [errorText, setErrorText] = useState<string>('')
  const [shortVideoInfo, setShortVideoInfo] = useState<ShortVideoInfo>({
    mainTitle: '',
    link: '',
    backgroundId: 0,
    aiToneTypeId: 0,
    bgmId: 0,
    voiceId: 0,
    animationId: 0,
    templateId: 0,
    subtitleId: 0,
    aiPromptDurationId: 0,
    amount: 0,
    urls: [],
    files: null,
  })
  const [shortVideoPriceInfo, setShortVideoPriceInfo] = useState({
    bgPrice: 0,
    bgmPrice: 0,
    voicePrice: 0,
    templatePrice: 0,
    promptDurationPrice: 0,
    subtitlePrice: 0,
  })

  //tooltip 관련 useState
  const [contentTooltipStatus, setContentTooltipStatus] =
    useState<boolean>(false)
  const [titleTooltip, setTitleTooltip] = useState<boolean>(false)
  const [animationTooltip, setAnimationTooltip] = useState<boolean>(false)
  const [bgTooltip, setBgTooltip] = useState<boolean>(false)
  const [durationTooltip, setDurationTooltip] = useState<boolean>(false)
  const [bgmTooltip, setBgmTooltip] = useState<boolean>(false)
  const [voiceTooltip, setVoiceTooltip] = useState<boolean>(false)
  const [subtitleTooltip, setSubtitleTooltip] = useState<boolean>(false)

  const [images, setImages] = useState<string[]>([])
  const [uploadImages, setUploadImages] = useState<File[]>([])
  const [selectedImages, setSelectedImages] = useState(
    new Array(images.length).fill(true)
  )
  const [selectedUploadImages, setSelectedUploadImages] = useState(
    new Array(uploadImages.length).fill(true)
  )

  // inputRef
  const mainTitleInputRef = useRef<null | HTMLInputElement>(null)
  const productsRef = useRef<HTMLInputElement | null>(null)

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      link: '',
      mainTitle: '',
      animationId: 0,
      voiceId: 0,
      bgmId: 0,
      backgroundId: 0,
      templateType: '',
      templateId: 0,
      aiToneTypeId: 0,
      subtitleId: 0,
      aiPromptDurationId: 0,
    },
  })

  const linkValue = watch('link')

  useEffect(() => {
    if (linkValue === '') {
      setImages([]) // link 값이 빈 문자열이 될 때 이미지를 초기화
      setSelectedImages([]) // link 값이 빈 문자열이 될 때 이미지를 초기화
      setUploadImages([])
      setSelectedUploadImages([]) // link 값이 빈 문자열이 될 때 이미지를 초기화
    }
  }, [linkValue])

  const onSubmit = async (data: any) => {
    const selectedImageData = images.filter((_, index) => selectedImages[index])
    const selectedUploadImageData = uploadImages.filter(
      (_, index) => selectedUploadImages[index]
    )

    const urls = selectedImageData || []
    const files = selectedUploadImageData || []

    userCheck()
    let {
      mainTitle,
      link,
      voiceId,
      bgmId,
      animationId,
      aiToneTypeId,
      backgroundId,
      templateId,
      subtitleId,
      templateType,
      aiPromptDurationId,
    } = data

    const prevInfo = {
      mainTitle,
      link,
      voiceId,
      bgmId,
      aiToneTypeId,
      backgroundId,
      templateId,
      subtitleId,
      templateType,
      animationId,
      aiPromptDurationId,
    }
    saveLocalStorage('shortVideoData', prevInfo)
    mainTitle = mainTitle.trim()

    // 가격 정보 및 계산(배경영상, 배경음악, 목소리)
    const bgInfo = bgList?.filter((el) => {
      if (el.id === backgroundId) {
        return el
      }
    })
    const bgmInfo = bgmList?.filter((el) => {
      if (el.id === bgmId) {
        return el
      }
    })
    const voiceInfo = voiceList?.filter((el) => {
      if (el.id === voiceId) {
        return el
      }
    })
    const promptInfo = aiPromptDurationList?.filter((el) => {
      if (el.id === aiPromptDurationId) {
        return el
      }
    })
    const subtitleInfo = subTitleList?.filter((el) => {
      if (el.id === subtitleId) {
        return el
      }
    })

    const temType: 'clip' | 'image' | 'mix' = templateType
    let templateInfo = templateList
      ? templateList[temType]?.list.filter((el) => el.id === templateId)
      : []

    if (temType === 'clip' && !templateInfo.length) {
      templateInfo = templateList
        ? templateList['etc']?.list.filter((el) => el.id === templateId)
        : []
    }

    setShortVideoPriceInfo({
      bgPrice:
        backgroundId !== 0 && bgInfo ? Number(bgInfo[0]?.originPrice) : 0,
      bgmPrice: bgmInfo ? Number(bgmInfo[0].originPrice) : 0,
      voicePrice: voiceInfo ? Number(voiceInfo[0].originPrice) : 0,
      promptDurationPrice: promptInfo ? Number(promptInfo[0].originPrice) : 0,
      templatePrice: templateInfo ? Number(templateInfo[0].originPrice) : 0,
      subtitlePrice: subtitleInfo ? Number(subtitleInfo[0].originPrice) : 0,
    })

    const shortVideoInfo = {
      mainTitle,
      link,
      voiceId,
      bgmId,
      animationId,
      backgroundId,
      templateId,
      subtitleId,
      aiToneTypeId,
      aiPromptDurationId,
      urls,
      amount: 0,
      files,
    }

    setShortVideoInfo(shortVideoInfo)
    setUseModal(true)
  }

  const [isOpen, setIsOpen] = useState(true)

  const toggleContent = () => {
    setIsOpen(!isOpen)
  }

  const [voicePlaying, setVoicePlaying] = useState<boolean[]>(
    new Array(voiceList?.length).fill(false)
  )

  const [bgmPlaying, setBgmPlaying] = useState<boolean[]>(
    new Array(bgmList?.length).fill(false)
  )

  const [toneTooltip, setToneTooltip] = useState<boolean[]>([])

  //여러개 tooltip 관리
  const tonRefs = useRef<(HTMLOrSVGElement | null)[]>([])
  const btnRef = useRef<HTMLButtonElement>(null)
  const handleMouseOver = (index: number) => {
    const updatedToneTooltipState = toneTooltip.map((value, idx) =>
      idx === index ? true : value
    )

    setToneTooltip(updatedToneTooltipState)
  }
  // console.log(toneTooltip)
  const handleMouseLeave = (index: number) => {
    const updatedToneTooltipState = toneTooltip.map((value, idx) =>
      idx === index ? false : value
    )
    setToneTooltip(updatedToneTooltipState)
  }

  // bgm / transition / voice handle function 부분
  const voiceRefs = useRef<(HTMLAudioElement | null)[]>([])
  const bgmRefs = useRef<(HTMLAudioElement | null)[]>([])

  const handleVoicePlayButtonClick = (status: boolean, index: number) => {
    const updatedPlayingState = voicePlaying.map((value, idx) =>
      idx === index ? status : false
    )

    setVoicePlaying(updatedPlayingState)

    if (voiceRefs.current[index]) {
      if (status) {
        voiceRefs.current[index]?.play()
      } else {
        voiceRefs.current[index]?.pause()
      }
    }
  }

  const handleBgmPlayButtonClick = (status: boolean, index: number) => {
    const updatedPlayingState = bgmPlaying.map((value, idx) =>
      idx === index ? status : false
    )
    setBgmPlaying(updatedPlayingState)

    if (bgmRefs.current[index]) {
      if (status) {
        bgmRefs.current[index]?.play()
      } else {
        bgmRefs.current[index]?.pause()
      }
    }
  }

  const setVideoData = () => {
    if (shortVideoData && userInfo) {
      const {
        mainTitle,
        link,
        voiceId,
        bgmId,
        aiToneTypeId,
        backgroundId,
        templateId,
        templateType,
        animationId,
        aiPromptDurationId,
        subtitleId,
      } = shortVideoData

      if (memory === 'true') {
        setValue('mainTitle', mainTitle)
        setValue('link', link)
      }
      if (backgroundId) {
        setValue('backgroundId', backgroundId)
      }

      if (subtitleId) {
        setValue('subtitleId', subtitleId)
      } else if (!subtitleId || Number(templateId) === 0) {
        if (subTitleList) {
          setValue('subtitleId', subTitleList[0].id)
        }
      }

      if (templateId) {
        setValue('templateId', templateId)
      } else if (!templateId || Number(templateId) === 0) {
        if (templateList) {
          setValue('templateId', templateList.mix.list[0].id)
          setValue('templateType', 'mix')
        }
      }

      if (templateType) {
        setValue('templateType', templateType)
      }

      if (animationId) {
        setValue('animationId', animationId)
      }

      if (aiToneTypeId) {
        setValue('aiToneTypeId', aiToneTypeId)
      }

      if (voiceId) {
        setValue('voiceId', voiceId)
      }

      if (bgmId) {
        setValue('bgmId', bgmId)
      }
      if (aiPromptDurationId) {
        setValue('aiPromptDurationId', aiPromptDurationId)
      }
    } else {
      if (toneList?.length) {
        setToneTooltip(new Array(toneList.length).fill(false))
        setValue('aiToneTypeId', toneList[0].id)
      }

      if (voiceList) {
        setValue('voiceId', voiceList[0].id)
      }
      if (templateList) {
        setValue('templateId', templateList.mix.list[0].id)
        setValue('templateType', 'mix')
      }
      if (bgList) {
        setValue('backgroundId', bgList[0].id)
      }
      if (animationList) {
        setValue('animationId', animationList[1].id)
      }
      if (bgmList) {
        setValue('bgmId', bgmList[0].id)
      }

      if (subTitleList) {
        setValue('subtitleId', subTitleList[0].id)
      }

      if (aiPromptDurationList) {
        setValue('aiPromptDurationId', aiPromptDurationList[0].id)
      }
    }
  }
  useEffect(() => {
    voiceRefs.current.forEach((audioRef, index) => {
      if (audioRef && !voicePlaying[index]) {
        audioRef.pause()
        audioRef.currentTime = 0
      }
    })
  }, [voicePlaying])

  useEffect(() => {
    bgmRefs.current.forEach((audioRef, index) => {
      if (audioRef && !bgmPlaying[index]) {
        audioRef.pause()
        audioRef.currentTime = 0
      }
    })
  }, [bgmPlaying])
  useEffect(() => {
    if (voiceList) {
      setVoicePlaying(new Array(voiceList?.length).fill(false))
    }
    if (bgmList) {
      setBgmPlaying(new Array(bgmList?.length).fill(false))
    }
    if (toneList) {
      setToneTooltip(new Array(toneList.length).fill(false))
    }
    setVideoData()
    if (amount && pType && bonus && pType === 'success') {
      setAmountValue(Number(amount) * (10 / 11))
      if (bonus) {
        setBonusValue(Number(bonus))
      }
      setModal(true)
    }
    if (mType === 'true') {
      if (btnRef.current) {
        btnRef.current.click()
        navigate(pathname)
      }
    }
  }, [
    location.search,
    userInfo,
    toneList,
    bgmList,
    voiceList,
    bgList,
    animationList,
    aiPromptDurationList,
    subTitleList,
  ])
  useEffect(() => {
    getMyPointRefetch()
  }, [])

  useEffect(() => {
    if (paymentKey && orderId && amount) {
      const confirmPaymentParams = {
        paymentKey,
        orderId,
        amount: Number(amount),
      }
      confirmPointMutate(confirmPaymentParams, {
        onSuccess: (data: any) => {
          const utmData = getUtmLocalStorage()
          if (utmData?.utmSource) {
            utmData.trackType = 'PURCHASE_POINT'
            saveUtmMutate(utmData, {
              onSuccess: () => {},
              onError: () => {},
            })
            purchasePoint()
          } else {
            pointPurchaseBtnClick('short-video-editor')
          }

          getMyPointRefetch()
          navigate(
            `/short-video-editor?m=true&amount=${amount}&pType=success&valid=${valid}&promotionCode=${promotionCode}&bonus=${bonus}`
          )
        },
        onError: () => {
          navigate('/payment-fail?path=short-video-editor')
        },
      })
    }
  }, [paymentKey, orderId, amount])
  useEffect(() => {
    if (isImageTypeModal || isMixTypeModal || isVideoTypeModal) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isImageTypeModal, isMixTypeModal, isVideoTypeModal])
  const handleCheckboxChange = (index: number) => {
    setSelectedImages((prev) => {
      const currentSelectionCount = prev.filter((selected) => selected).length
      const newSelection = !prev[index]
      prev.map((selected, i) => (i === index ? !selected : selected))
      // 이미 3개가 선택된 상태에서 추가 선택을 막습니다.
      if (currentSelectionCount >= 20 && newSelection) {
        return prev
      }
      return prev.map((selected, i) => (i === index ? newSelection : selected))
    })
  }
  const handleUploadCheckboxChange = (index: number) => {
    setSelectedUploadImages((prev) => {
      const currentSelectionCount = prev.filter((selected) => selected).length
      const newSelection = !prev[index]
      prev.map((selected, i) => (i === index ? !selected : selected))
      // 이미 3개가 선택된 상태에서 추가 선택을 막습니다.
      if (currentSelectionCount >= 3 && newSelection) {
        return prev
      }
      return prev.map((selected, i) => (i === index ? newSelection : selected))
    })
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files)
      setUploadImages((prevImages) => {
        const newImages = [...prevImages, ...selectedFiles]
        setSelectedUploadImages((prevSelected) => {
          const additionalSelectionCount = selectedFiles.length
          const currentSelectionCount = prevSelected.filter(
            (selected) => selected
          ).length

          // 추가된 파일이 최대 선택 개수를 초과하지 않도록 합니다.
          if (currentSelectionCount + additionalSelectionCount > 3) {
            const allowedSelectionCount = 3 - currentSelectionCount
            return [
              ...prevSelected,
              ...new Array(allowedSelectionCount).fill(true),
              ...new Array(selectedFiles.length - allowedSelectionCount).fill(
                false
              ),
            ]
          } else {
            return [
              ...prevSelected,
              ...new Array(selectedFiles.length).fill(true),
            ]
          }
        })
        return newImages
      })
    }
  }

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    document.getElementById('file-input')!.click()
  }

  const handleBlur = async (url: string) => {
    if (url) {
      try {
        const result = await getShortImageList(url)

        const { title, images } = result
        setValue('mainTitle', title)
        // setTitle(title);
        setImages(images)
        // setSelectedImages(new Array(images.length).fill(true))

        setSelectedImages((prevSelected) => {
          const additionalSelectionCount = images.length
          const currentSelectionCount = prevSelected.filter(
            (selected) => selected
          ).length

          // 추가된 파일이 최대 선택 개수를 초과하지 않도록 합니다.
          if (currentSelectionCount + additionalSelectionCount > 20) {
            const allowedSelectionCount = 20 - currentSelectionCount

            return [
              ...prevSelected,
              ...new Array(allowedSelectionCount).fill(true),
              ...new Array(images.length - allowedSelectionCount).fill(false),
            ]
          } else {
            return [...prevSelected, ...new Array(images.length).fill(true)]
          }
        })
        setSuccess(true)
      } catch (e) {
        // @ts-ignore
        const serverErrorMsg = e.response?.data?.message || ''
        console.log(serverErrorMsg)
        setErrorText(serverErrorMsg)
        setEssentialCheck(true)
        setHasLinkError(true)
        setSuccess(false)
      }
      setCrawlingLoading(false)
    }
  }

  const handleCrwaling = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setCrawlingLoading(true)
    await handleBlur(linkValue)
  }

  const handleClipType = () => {
    const templateId = watch('templateId')
    const clip = templateList?.clip?.list.find((el) => el.id === templateId)
    const etc = templateList?.etc?.list.find((el) => el.id === templateId)
    if (etc) {
      return (
        <>
          <div className="flex ">
            <span>{etc.name}</span>
            {new Array(etc.cashRate).fill(0).map((el, idx) => (
              <img
                src="assets/img/cash.png"
                alt="cash"
                key={idx}
                className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
              />
            ))}
          </div>
        </>
      )
    } else if (clip) {
      return (
        <>
          <div className="flex ">
            <span>{clip.name}</span>
            {new Array(clip.cashRate).fill(0).map((el, idx) => (
              <img
                src="assets/img/cash.png"
                alt="cash"
                key={idx}
                className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
              />
            ))}
          </div>
        </>
      )
    } else {
      return <></>
    }
  }

  const handleRemoveLink = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    console.log('remove')
    setValue('link', '')
    setValue('mainTitle', '')
    setSuccess(false)
    setHasLinkError(false)
    setCrawlingLoading(false)
  }

  const getGridItemStyle = (selected: boolean): CSSProperties => ({
    width: '130px',
    height: '130px',
    position: 'relative',
    borderRadius: '4px',
    border: '1px solid #D9DEE8',
    overflow: 'hidden', // 이미지가 부모의 경계를 넘어가지 않도록 설정
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFFFFF',
    opacity: selected ? 1 : 0.3, // 선택되지 않은 경우 투명도 조절
  })

  // @ts-ignore
  const styles: { [key: string]: CSSProperties } = {
    input: {
      width: '100%',
      padding: '10px',
      marginBottom: '10px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
    gridContainerMobile: {
      gridTemplateColumns: 'repeat(3, 1fr)', // 각 아이템의 너비를 유지하면서 자동으로 간격 조정
      justifyItems: 'center',
    },
    gridItemMobile: {
      width: '93px',
      height: '93px',
    },
    image: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      objectFit: 'contain', // 비율을 유지하며 부모를 꽉 채우도록 설정
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    checkbox: {
      marginRight: '8px',
      width: '16px',
      height: '16px',
    },
    label: {
      fontSize: '16px',
      color: '#333',
    },
  }

  const isDisabled =
    selectedImages.every((value) => !value) &&
    selectedUploadImages.every((value) => !value)

  return (
    <>
      <div className="pt-[45px] md:max-w-[816px] max-w-[375px] my-0 mx-auto flex flex-col items-center gap-[16px]">
        <div className="flex flex-col items-center w-[343px] md:w-full gap-2">
          <h1 className="font-bold text-[28px] md:text-[48px]">숏폼 만들기</h1>
          <p className="text-[20px] bg-clip-text text-transparent bg-gradient-to-r from-[rgba(96,149,255,1)] to-[rgba(213,18,231,1)]">
            영상 효과 할인 이벤트!
          </p>
          <img
            src="assets/img/short_video_editor/sale.png"
            alt="sale"
            width={179}
            height={41}
          />
        </div>

        <form
          onSubmit={handleSubmit(onSubmit, (err) => {
            userCheck()
            const { mainTitle, link } = err
            console.log(err)

            const selectErrorText = (mainTitle: any, link: any) => {
              let mainTitleErrorText =
                '숏폼 제목(50자 제한) 입력을 하셔야 영상 생성 요청이 가능합니다.'
              let linkErrorText = '뉴스 링크'
              let errorStr = ''
              if (mainTitle && link) {
                errorStr = `${linkErrorText}, ${mainTitleErrorText}`

                if (mainTitleInputRef.current) {
                  mainTitleInputRef.current.focus()
                  mainTitleInputRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              } else if (link) {
                errorStr = `${linkErrorText} 입력을 하셔야 영상 생성 요청이 가능합니다.`

                if (mainTitleInputRef.current) {
                  mainTitleInputRef.current.focus()
                  mainTitleInputRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              }

              setErrorText(errorStr)
            }
            selectErrorText(mainTitle, link)
            setEssentialCheck(true)
          })}
          className={`flex flex-col gap-[20px] mt-[20px] `}
        >
          <ShadowBox className="py-[40px]  md:w-[816px]">
            <div className="flex flex-col gap-[16px]  w-full ">
              <div className="flex flex-col gap-[16px]">
                <div className="flex flex-col w-[343px] md:w-full ml-4 md:ml-0">
                  <div className="relative w-[210px]">
                    <h3 className="font-bold text-[#2E343D] text-[24px] ">
                      컨텐츠 링크 입력
                    </h3>
                    <ContentTooltip
                      setContentTooltipStatus={setContentTooltipStatus}
                      contentTooltipStatus={contentTooltipStatus}
                    />
                  </div>
                  <p className="text-main text-[14px]">
                    <span className="font-bold">
                      네이버 블로그, 티스토리, 워드프레스, 네이버
                      뉴스/연예/스포츠
                    </span>
                    만 동작하며, 저작권에 유의하세요
                  </p>
                </div>
                <div className="flex flex-col items-center w-full md:w-[736px] gap-[8px]">
                  <div className="relative">
                    <Controller
                      render={({ field: { onBlur, value, ...field } }) => (
                        <>
                          <Input
                            userCheck={userCheck}
                            inputRef={mainTitleInputRef}
                            value={value}
                            error={value.length > 0 && hasLinkError}
                            {...field}
                            placeholder="컨텐츠 링크 붙여넣기"
                          />
                        </>
                      )}
                      name={'link'}
                      rules={{
                        required: {
                          value: true,
                          message: 'URL 입력은 필수입니다!',
                        },
                      }}
                      control={control}
                    />
                    <div className="flex gap-[16px] absolute right-4 bottom-0 top-0 items-center ">
                      <button
                        onClick={handleRemoveLink}
                        disabled={watch(`link`) == '' || isCrawlingLoading}
                      >
                        <img
                          className="w-[24px] h-[24px]  md:w-[32px] md:h-[32px]"
                          src="assets/img/icon_circle_x.png"
                          alt="x"
                        />
                      </button>
                      <button
                        className="bg-[#6B8ACB] hover:bg-[#8FABE5] active:scale-95 text-white px-[20px] py-[10px]  md:px-[24px] md:py-[16px] md:w-[76px] md:h-[51px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                        onClick={handleCrwaling}
                        disabled={watch(`link`) == '' || isCrawlingLoading}
                      >
                        {isCrawlingLoading ? (
                          <span className="loader"></span> // 로더 컴포넌트
                        ) : (
                          '적용' // 적용 버튼 텍스트
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[16px]">
                <div className=" flex flex-col ml-4 md:ml-0">
                  <div className="relative flex items-center gap-3">
                    {' '}
                    <h3 className="font-bold text-[#2E343D] text-[24px] ">
                      숏폼 제목 입력
                    </h3>
                    <ShortVideoTitleTooltip
                      setTitleTooltip={setTitleTooltip}
                      titleTooltip={titleTooltip}
                    />
                  </div>

                  <p className="text-main text-[14px]">
                    매력적인 제목을 정해주세요!
                  </p>
                </div>
                <div className="flex flex-col items-center w-full md:w-[736px] gap-[8px]">
                  <div className="relative">
                    <Controller
                      render={({ field }) => (
                        <>
                          <Input
                            userCheck={userCheck}
                            inputRef={mainTitleInputRef}
                            error={
                              field.value.length > 50 ||
                              !PRODUCT_NAME_PATTERN.test(field.value)
                                ? true
                                : false
                            }
                            {...field}
                            placeholder="숏폼 제목을 입력해주세요"
                            maxLength={50}
                          />
                          <div
                            className={`flex gap-[4px] mt-[8px] ${
                              (field.value.length > 50 ||
                                !PRODUCT_NAME_PATTERN.test(field.value)) &&
                              'text-error'
                            }`}
                          >
                            <span className="md:text-base text-[14px]">
                              {field.value.length}/50
                            </span>
                            <span className="md:text-base text-[14px]">{`이모지, 한자(漢字), 꺽쇠괄호(< >) 는 허용되지 않습니다.`}</span>
                          </div>
                        </>
                      )}
                      name={'mainTitle'}
                      rules={{
                        required: {
                          value: true,
                          message: '숏폼 제목은 필수입니다!',
                        },
                        maxLength: {
                          value: 90,
                          message: '유튜브 영상 제목의 길이가 50자를 넘습니다.',
                        },
                        validate:
                          watch(`mainTitle`) !== ''
                            ? (value) => {
                                return (
                                  PRODUCT_NAME_PATTERN.test(value) ||
                                  '이모지와 꺽쇠괄호(< >)는 허용되지 않습니다.'
                                )
                              }
                            : undefined,
                      }}
                      control={control}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-[16px]">
                <div className="flex flex-col w-[343px] md:w-full ml-4 md:ml-0">
                  <div className="mb-4">
                    <label className="md:ml-0 font-bold text-[#2E343D] text-[18px] ">
                      컨텐츠 이미지
                    </label>
                    <p className="text-error text-[14px]">
                      사용할 수 있는 이미지가 없는 경우, 숏폼 만들기가 진행되지
                      않습니다 🙏
                    </p>
                  </div>
                  {isSuccess && (
                    <div
                      style={{
                        background: '#F6F8FB',
                        borderRadius: '10px',
                        padding: '20px',
                        maxHeight: 'calc(2 * 230px)', // 2줄까지만 보이도록 높이 제한 (2줄 높이 + 갭)
                        overflowY: 'auto', // 스크롤 가능하게 설정
                        scrollbarWidth: 'thin', // Firefox용 기본 스크롤바 폭 설정
                        scrollbarColor: '#888 #e0e0e0', // Firefox용 스크롤바 색상 설정
                      }}
                    >
                      <div>
                        {images.length === 0 ? (
                          <p className="text-error text-[14px] mb-5">
                            입력하신 컨텐츠 링크에 사용할 수 있는 이미지가
                            없습니다. (이미지가 없는 경우, 영상만 있는 경우 등)
                            <br />
                            이미지를 직접 추가해서 숏폼을 만들어보세요!
                          </p>
                        ) : (
                          <div>
                            <div className="text-[14px]  mb-3">
                              <span className="font-bold">
                                불러온 이미지{' '}
                                {selectedImages.filter((image) => image).length}
                                /{images.length}
                              </span>{' '}
                              (최대 20장 선택 가능)
                            </div>
                            <div
                              style={{
                                ...styles.gridContainer,
                                ...(window.innerWidth <= 768
                                  ? styles.gridContainerMobile
                                  : {}),
                              }}
                            >
                              {images.map((src, index) => (
                                <div
                                  style={{ marginBottom: '20px' }}
                                  key={index}
                                >
                                  <div
                                    style={{
                                      ...getGridItemStyle(
                                        selectedImages[index]
                                      ),
                                      ...(window.innerWidth <= 768
                                        ? styles.gridItemMobile
                                        : {}),
                                    }}
                                  >
                                    <img
                                      src={
                                        typeof src === 'string'
                                          ? src.includes('pstatic')
                                            ? `${process.env.REACT_APP_API_URL}/crawling/proxy-image?url=${src}`
                                            : src
                                          : URL.createObjectURL(src)
                                      }
                                      alt={`Image ${index + 1}`}
                                      style={styles.image}
                                    />
                                  </div>
                                  <div style={styles.checkboxContainer}>
                                    <input
                                      type="checkbox"
                                      checked={selectedImages[index]}
                                      onChange={() =>
                                        handleCheckboxChange(index)
                                      }
                                      style={styles.checkbox}
                                    />
                                    <span style={styles.label}>
                                      {String(index + 1).padStart(2, '0')}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      <hr />
                      <div className="text-[14px]  mt-5 mb-1">
                        <span className="font-bold">
                          업로드한 이미지{' '}
                          {selectedUploadImages.filter((image) => image).length}
                          /{uploadImages.length}
                        </span>{' '}
                        (최대 3장 선택 가능)
                      </div>
                      <div className="flex items-center space-x-2 mb-3">
                        <span className="text-[#8F929B] text-[16px] text-sm">
                          최대 5MB | jpg, jpeg, png
                        </span>
                        <button
                          onClick={handleButtonClick}
                          className="flex items-center space-x-1 px-3 py-2 text-white rounded-full bg-[#6B8ACB] text-[14px] font-bold"
                        >
                          <img src="assets/img/icon_image.png" width="17px" />
                          <span>파일 업로드</span>
                        </button>
                        <input
                          id="file-input"
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          multiple
                          className="hidden"
                          onChange={handleFileChange}
                        />
                      </div>
                      <div
                        style={{
                          ...styles.gridContainer,
                          ...(window.innerWidth <= 768
                            ? styles.gridContainerMobile
                            : {}),
                        }}
                      >
                        {uploadImages.map((src, index) => (
                          <div style={{ marginBottom: '20px' }} key={index}>
                            <div
                              style={{
                                ...getGridItemStyle(
                                  selectedUploadImages[index]
                                ),
                                ...(window.innerWidth <= 768
                                  ? styles.gridItemMobile
                                  : {}),
                              }}
                            >
                              <img
                                src={
                                  typeof src === 'string'
                                    ? src
                                    : URL.createObjectURL(src)
                                }
                                alt={`Image ${index + 1}`}
                                style={styles.image}
                              />
                            </div>
                            <div style={styles.checkboxContainer}>
                              <input
                                type="checkbox"
                                checked={selectedUploadImages[index]}
                                onChange={() =>
                                  handleUploadCheckboxChange(index)
                                }
                                style={styles.checkbox}
                              />
                              <span style={styles.label}>
                                {String(index + 1).padStart(2, '0')}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </ShadowBox>

          <ShadowBox
            className={classNames('py-[40px] px-[16px] md:w-[816px]', {
              'opacity-30 pointer-events-none': !isSuccess,
            })}
          >
            <div className="flex flex-col gap-[40px] md:gap-0 w-[343px] md:w-[736px]">
              <div className="flex flex-col items-center  mt-[16px] md:mb-[40px] ">
                <p className="font-bold text-[#2E343D] text-[24px] ">
                  효과 선택
                </p>
                <p className="text-main text-[14px]">
                  원하는 분위기의 영상 생성을 위해 각 효과를 선택해주세요!
                </p>
              </div>
              <CSSTransition
                in={isOpen}
                timeout={200}
                classNames="content"
                unmountOnExit
              >
                <div className="">
                  <div className="ai-prompt mb-[40px]">
                    <p className=" text-[18px] mb-[16px] flex items-center gap-1 relative">
                      <span className="font-bold">이미지 애니메이션 효과 </span>
                      <AnimationTitleTooltip
                        setAnimationTooltip={setAnimationTooltip}
                        animationTooltip={animationTooltip}
                      />
                    </p>

                    <div className="flex gap-[80px] ">
                      {animationList?.map((animation, index) => {
                        return (
                          <>
                            <div className="flex " key={index}>
                              <div className="flex h-full items-center">
                                <Controller
                                  render={({ field }) => (
                                    <CircularCheckbox
                                      {...field}
                                      id={index}
                                      checked={
                                        watch('animationId') === animation.id
                                      }
                                      disabled={!userInfo}
                                      onChange={() =>
                                        setValue('animationId', animation.id)
                                      }
                                      label={animation.name}
                                    />
                                  )}
                                  name="animationId"
                                  control={control}
                                />
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>

                  <div className="background mb-[40px]">
                    <p className=" mb-[16px] flex items-center gap-1 relative">
                      <span className=" text-[18px] font-bold">
                        화면 구성 + 배경 효과
                      </span>
                      <span
                        className="w-[36px] h-[17px]  flex justify-center items-center rounded-[4px] font-bold text-[11px] text-white"
                        style={{
                          background: `linear-gradient(to right, rgba(96, 149, 255, 1),rgba(213, 18, 231, 1))`,
                        }}
                      >
                        SALE
                      </span>
                    </p>

                    <div className="grid grid-cols-3 gap-x-[8px] md:gap-x-[10px] gap-y-[16px] ">
                      {['image', 'mix', 'clip'].map((el, index) => {
                        let btnTitle = ''
                        let subTitle = ''
                        let type: 'clip' | 'image' | 'mix' = watch(
                          'templateType'
                        ) as 'clip' | 'image' | 'mix'

                        const handleTypeTitle = () => {
                          if (el === 'image') {
                            if (isMobile) {
                              btnTitle = '이미지 타입'
                              subTitle =
                                '이미지를 화면 전체에 적용하고, 제목의 스타일을 고르는 타입'
                            } else {
                              btnTitle = '이미지 타입에서 고르기'
                              subTitle =
                                '이미지를 화면 전체에 적용하고, 제목의 스타일을 고르는 타입'
                            }
                          } else if (el === 'mix') {
                            if (isMobile) {
                              btnTitle = '믹스 타입'
                              subTitle =
                                '이미지&제목의 화면 구성과 배경 영상을 믹스해서 고르는 타입'
                            } else {
                              btnTitle = '믹스 타입에서 고르기'
                              subTitle =
                                '이미지&제목의 화면 구성과 배경 영상을 믹스해서 고르는 타입'
                            }
                          } else if (el === 'clip') {
                            if (isMobile) {
                              btnTitle = '영상 타입'
                              subTitle =
                                '영상으로 만들어진 템플릿에 이미지와 제목을 적용하는 타입 '
                            } else {
                              btnTitle = '영상 타입에서 고르기'
                              subTitle =
                                '영상으로 만들어진 템플릿에 이미지와 제목을 적용하는 타입 '
                            }
                          }
                        }
                        const handleTypeClick = () => {
                          if (el === 'image') {
                            setImageTypeModal((prev) => !prev)
                          } else if (el === 'mix') {
                            setMixTypeModal((prev) => !prev)
                          } else if (el === 'clip') {
                            setVideoTypeModal((prev) => !prev)
                          }
                        }
                        handleTypeTitle()
                        return (
                          <div
                            className={classNames(
                              'bg-[#F6F8FB] h-auto flex flex-col items-center md:px-5 md:py-5 py-[10px] px-[6px] rounded-[10px]',
                              {
                                ' outline outline-4':
                                  watch('templateType') === el,
                              }
                            )}
                            style={{ outlineColor: '#00B7B9' }}
                            onClick={handleTypeClick}
                          >
                            <img
                              src={
                                index !== 2
                                  ? `assets/img/short_video_editor/${index}.png`
                                  : 'https://pub.angelleague.io/fast_cut/template/clip3.gif'
                              }
                              alt="0"
                              height={240}
                              className="w-[101px] md:w-[136px]"
                            />
                            <button
                              className="py-2 font-bold text-[14px] text-white bg-[#6B8ACB] rounded-[40px] mt-2 w-[101px] md:w-[160px]"
                              type="button"
                            >
                              {btnTitle}
                            </button>

                            {watch('templateType') === el ? (
                              <p className="w-full text-center mt-2 bg-white border-[1px] border-[#D9DEE8] rounded-[4px] py-1 flex md:flex-row flex-col md:justify-center items-center gap-2 ">
                                {templateList && type !== 'clip' ? (
                                  <>
                                    {templateList[type]?.list
                                      .filter(
                                        (el) => el.id === watch('templateId')
                                      )
                                      .map((el) => {
                                        return (
                                          <>
                                            <div className="flex ">
                                              <span>{el.name}</span>
                                              {new Array(el.cashRate)
                                                .fill(0)
                                                .map((el, idx) => (
                                                  <img
                                                    src="assets/img/cash.png"
                                                    alt="cash"
                                                    key={idx}
                                                    className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                                                  />
                                                ))}
                                            </div>
                                          </>
                                        )
                                      })}
                                    {type === 'mix' &&
                                      bgList
                                        ?.filter(
                                          (el) =>
                                            el.id === watch('backgroundId')
                                        )
                                        .map((el) => {
                                          return (
                                            <>
                                              <div className="flex ">
                                                <span>{el.name}</span>
                                                {new Array(el.cashRate)
                                                  .fill(0)
                                                  .map((el, idx) => (
                                                    <img
                                                      src="assets/img/cash.png"
                                                      alt="cash"
                                                      key={idx}
                                                      className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                                                    />
                                                  ))}
                                              </div>
                                            </>
                                          )
                                        })}
                                  </>
                                ) : (
                                  templateList &&
                                  type === 'clip' &&
                                  handleClipType()
                                )}
                              </p>
                            ) : (
                              <p className="text-[14px] text-center mt-2">
                                {subTitle}
                              </p>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>

                  <div className="subtitle mb-[40px]">
                    <p className="text-[18px] mb-[16px] flex items-center gap-1 relative">
                      <span className="font-bold">나레이션 자막</span>
                      <SubtitleToolltip
                        subTitleList={subTitleList}
                        setSubtitleTooltip={setSubtitleTooltip}
                        subtitleTooltip={subtitleTooltip}
                      />
                      <span
                        className="w-[36px] h-[17px]  flex justify-center items-center rounded-[4px] font-bold text-[11px] text-white"
                        style={{
                          background: `linear-gradient(to right, rgba(96, 149, 255, 1),rgba(213, 18, 231, 1))`,
                        }}
                      >
                        SALE
                      </span>
                    </p>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[53.67px] gap-y-[16px]">
                      {subTitleList?.map((subtitle, index) => {
                        return (
                          <>
                            <div
                              className="w-full flex justify-between md:items-center"
                              key={index}
                            >
                              <div className="flex ">
                                <Controller
                                  render={({ field }) => (
                                    <CircularCheckbox
                                      {...field}
                                      id={index}
                                      checked={
                                        watch('subtitleId') === subtitle.id
                                      }
                                      disabled={!userInfo}
                                      onChange={() =>
                                        setValue('subtitleId', subtitle.id)
                                      }
                                      label={subtitle.name}
                                    />
                                  )}
                                  name="subtitleId"
                                  control={control}
                                />
                                {new Array(subtitle.cashRate)
                                  .fill(0)
                                  .map((el) => (
                                    <>
                                      {' '}
                                      <img
                                        src="assets/img/cash.png"
                                        alt="cash"
                                        className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                                      />
                                    </>
                                  ))}
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>
                  <div className="ai-tone mb-[40px]">
                    <p className="font-bold text-[18px] mb-[16px]">
                      AI 생성 나레이션 말투
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[53.67px] gap-y-[16px]">
                      {toneList?.map((tone, index) => {
                        return (
                          <>
                            <div
                              className="w-full flex justify-between md:items-center"
                              key={index}
                            >
                              <div className="flex items-center gap-[10px] h-full">
                                <Controller
                                  render={({ field }) => (
                                    <CircularCheckbox
                                      {...field}
                                      id={index}
                                      checked={
                                        watch('aiToneTypeId') === tone.id
                                      }
                                      disabled={!userInfo}
                                      onChange={() =>
                                        setValue('aiToneTypeId', tone.id)
                                      }
                                      label={tone.name}
                                    />
                                  )}
                                  name="aiToneTypeId"
                                  control={control}
                                />

                                <AiToneTooltip
                                  tone={tone}
                                  index={index}
                                  tonRefs={tonRefs}
                                  toneTooltip={toneTooltip}
                                  handleMouseOver={handleMouseOver}
                                  handleMouseLeave={handleMouseLeave}
                                />
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>

                  <div className="tts  md:mb-[40px]">
                    <p className="text-[18px] mb-[16px] flex items-center gap-1 relative">
                      <span className="font-bold">목소리</span>
                      <VoiceTooltip
                        voiceList={voiceList}
                        setVoiceTooltip={setVoiceTooltip}
                        voiceTooltip={voiceTooltip}
                      />
                      <span
                        className="w-[36px] h-[17px]  flex justify-center items-center rounded-[4px] font-bold text-[11px] text-white"
                        style={{
                          background: `linear-gradient(to right, rgba(96, 149, 255, 1),rgba(213, 18, 231, 1))`,
                        }}
                      >
                        SALE
                      </span>
                    </p>

                    <div className="flex justify-between w-[156.5px] md:w-[193px] mb-[16px] gap-[8px]">
                      <div className="flex">
                        <Controller
                          render={({ field }) => (
                            <CircularCheckbox
                              {...field}
                              id={0}
                              checked={watch('voiceId') === voiceList[0]?.id}
                              disabled={!userInfo}
                              onChange={() =>
                                setValue(
                                  'voiceId',
                                  voiceList[0]?.id ? voiceList[0]?.id : 0
                                )
                              }
                              label={`${voiceList[0]?.name}`}
                            />
                          )}
                          name="voiceId"
                          control={control}
                        />
                        {new Array(voiceList[0].cashRate).fill(0).map((el) => (
                          <>
                            {' '}
                            <img
                              src="assets/img/cash.png"
                              alt="cash"
                              className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                            />
                          </>
                        ))}
                      </div>
                      <div className="flex gap-[12px]">
                        <button
                          type="button"
                          onClick={() => {
                            handleVoicePlayButtonClick(!voicePlaying[0], 0)
                          }}
                        >
                          <audio
                            ref={(ref) => {
                              voiceRefs.current[0] = ref
                              if (ref) {
                                ref.onended = () => {
                                  const updatedPlayingState = [...voicePlaying]
                                  updatedPlayingState[0] = false
                                  setVoicePlaying(updatedPlayingState)
                                }
                              }
                            }}
                          >
                            <source
                              src={
                                voiceList[0]?.path
                                  ? voiceList[0]?.path
                                  : 'https://pub.angelleague.io/fast_cut/voice/output_gtts1.5.mp3'
                              }
                              type="audio/mpeg"
                            />
                          </audio>
                          <img
                            src={
                              !voicePlaying[0]
                                ? 'assets/img/icon_play.png'
                                : 'assets/img/icon_pause.png'
                            }
                            alt="play"
                            className="w-[29.25px] h-[29.25px]"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-x-[30px] md:gap-x-[53.67px] gap-y-[16px] mb-[40px]">
                      {voiceList?.map((voice, index) => {
                        if (index === 0) return null
                        return (
                          <>
                            <div
                              className="md:w-[193px] w-full flex gap-[8px] justify-between md:items-center"
                              key={index}
                            >
                              <div className="flex">
                                <Controller
                                  render={({ field }) => (
                                    <CircularCheckbox
                                      {...field}
                                      id={index}
                                      checked={watch('voiceId') === voice.id}
                                      disabled={!userInfo}
                                      onChange={() =>
                                        setValue('voiceId', voice.id)
                                      }
                                      label={voice.name}
                                    />
                                  )}
                                  name="voiceId"
                                  control={control}
                                />
                                {new Array(voice.cashRate).fill(0).map((el) => (
                                  <>
                                    {' '}
                                    <img
                                      src="assets/img/cash.png"
                                      alt="cash"
                                      className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                                    />
                                  </>
                                ))}
                              </div>

                              <div className="flex gap-[12px]">
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleVoicePlayButtonClick(
                                      !voicePlaying[index],
                                      index
                                    )
                                  }
                                >
                                  <audio
                                    ref={(ref) => {
                                      voiceRefs.current[index] = ref
                                      if (ref) {
                                        ref.onended = () => {
                                          const updatedPlayingState = [
                                            ...voicePlaying,
                                          ]
                                          updatedPlayingState[index] = false
                                          setVoicePlaying(updatedPlayingState)
                                        }
                                      }
                                    }}
                                  >
                                    <source
                                      src={voice.shortPath}
                                      type="audio/mpeg"
                                    />
                                  </audio>
                                  <img
                                    src={
                                      !voicePlaying[index]
                                        ? 'assets/img/icon_play.png'
                                        : 'assets/img/icon_pause.png'
                                    }
                                    alt="play"
                                    className="w-[29.25px] h-[29.25px]"
                                  />
                                </button>
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>

                  <div className="bgm  md:mb-[40px]">
                    <p className=" text-[18px] mb-[16px] flex items-center gap-1 relative">
                      <span className="font-bold">배경음악</span>
                      <BgmTooltip
                        setBgmTooltip={setBgmTooltip}
                        bgmTooltip={bgmTooltip}
                      />
                    </p>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-x-[22px] gap-y-[16px]">
                      {bgmList?.map((bgm, index) => {
                        return (
                          <>
                            <div
                              className="flex items-center  justify-between space-x-2  w-full md:w-[228px]"
                              key={index}
                            >
                              <div className="flex">
                                <Controller
                                  render={({ field }) => (
                                    <CircularCheckbox
                                      {...field}
                                      id={index}
                                      checked={watch('bgmId') === bgm.id}
                                      onChange={() => setValue('bgmId', bgm.id)}
                                      disabled={!userInfo}
                                      label={bgm.name}
                                    />
                                  )}
                                  name="bgmId"
                                  control={control}
                                />
                                {new Array(bgm.cashRate).fill(0).map((el) => (
                                  <>
                                    {' '}
                                    <img
                                      src="assets/img/cash.png"
                                      alt="cash"
                                      className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                                    />
                                  </>
                                ))}
                              </div>
                              <div className="flex gap-[12px]">
                                <audio
                                  ref={(ref) => {
                                    bgmRefs.current[index] = ref
                                    if (ref) {
                                      ref.onended = () => {
                                        const updatedBgmPlayingState = [
                                          ...bgmPlaying,
                                        ]
                                        updatedBgmPlayingState[index] = false
                                        setBgmPlaying(updatedBgmPlayingState)
                                      }
                                    }
                                  }}
                                >
                                  <source src={bgm.path} type="audio/mpeg" />
                                </audio>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleBgmPlayButtonClick(
                                      !bgmPlaying[index],
                                      index
                                    )
                                  }}
                                >
                                  <img
                                    src={
                                      !bgmPlaying[index]
                                        ? 'assets/img/icon_play.png'
                                        : 'assets/img/icon_pause.png'
                                    }
                                    alt="play"
                                    className="w-[29.25px] h-[29.25px]"
                                  />
                                </button>
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>

                  <div className="ai-tone my-[40px]">
                    <p className=" text-[18px] mb-[16px] flex items-center gap-1 relative">
                      <span className="font-bold"> 숏폼 길이 (재생시간)</span>
                      <DurationToolitip
                        durationList={aiPromptDurationList}
                        setDurationTooltip={setDurationTooltip}
                        durationTooltip={durationTooltip}
                      />
                      <span
                        className="w-[36px] h-[17px]  flex justify-center items-center rounded-[4px] font-bold text-[11px] text-white"
                        style={{
                          background: `linear-gradient(to right, rgba(96, 149, 255, 1),rgba(213, 18, 231, 1))`,
                        }}
                      >
                        SALE
                      </span>
                    </p>

                    <div className="flex ">
                      <div className="grid grid-cols-2 md:gap-x-[160px] gap-y-[16px]">
                        {aiPromptDurationList?.map((el) => {
                          return (
                            <div className="w-full flex justify-between md:items-center">
                              <div className="flex items-center h-full">
                                <CircularCheckbox
                                  id={0}
                                  checked={
                                    watch('aiPromptDurationId') === el.id
                                  }
                                  disabled={!userInfo}
                                  label={el.name}
                                  onChange={() =>
                                    setValue('aiPromptDurationId', el.id)
                                  }
                                />
                                <div className="h-full">
                                  {new Array(el.cashRate).fill(1).map((el) => (
                                    <img
                                      src="assets/img/cash.png"
                                      alt="cash"
                                      className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </CSSTransition>
              <div className="flex items-center justify-center">
                <button
                  className="flex items-center justify-center gap-[8px] w-full py-[12.5px] bg-[#EEF1F7] rounded-[40px]"
                  type="button"
                  onClick={toggleContent}
                >
                  <span>{isOpen ? '접기' : '펼치기'}</span>
                  <img
                    src="assets/img/icon_up_arrow.png"
                    alt="arrow"
                    className={`w-[12.8px] h-[8.25px]  transition-transform duration-500 ${
                      isOpen ? '' : 'rotate-180'
                    }`}
                  />
                </button>
              </div>
            </div>
          </ShadowBox>
          <div
            className="text-[16px] text-center"
            style={{
              opacity: isDisabled ? 0.3 : 1,
              pointerEvents: isDisabled ? 'none' : 'auto',
            }}
          >
            패스트컷은 사용자가 제공하는 URL에 있는 텍스트/이미지/영상을 토대로
            영상을 만들고 있으며,
            <br /> 링크 내의 텍스트나 이미지, 영상 등이 영상에 사용될 수
            있습니다.
            <br /> 이에 대한 지적재산권 침해 여부는 사용자가 확인하여야 하며,
            이에 관하여 패스트컷은 책임이 없습니다.
          </div>
          <div>
            <div
              className="flex flex-col justify-center items-center gap-[20px]"
              style={{
                opacity: !isSuccess ? 0.3 : 1,
                pointerEvents: isDisabled ? 'none' : 'auto',
              }}
            >
              <button
                type="submit"
                className={`h-[60px] flex items-center justify-center bg-main gap-[4px] w-[343px] md:w-full border-none rounded-[30px] hover:bg-hover text-[#FFFFFF] font-bold`}
                ref={btnRef}
                disabled={!isSuccess}
              >
                <span>숏폼 생성 요청</span>
              </button>
            </div>
          </div>
        </form>

        <GuideToUse />
        {essentialCheck && (
          <Modal isModal={essentialCheck}>
            <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
              <div className="flex flex-col items-center justify-center h-full gap-[24px]">
                <p className="font-bold text-[18px]">
                  🙌 필수 정보를 확인해주세요!
                </p>
                <p className="text-[14px]">{errorText}</p>
                {/* <p className="text-red-500">{errorMessage}</p> */}
              </div>
            </div>
            <div className="w-[400px] bg-main h-[60px] border-b  border-main rounded-b-[20px] flex justify-center items-center">
              <button
                className="text-white w-[400px]"
                onClick={() => {
                  setEssentialCheck(false)
                }}
              >
                확인
              </button>
            </div>
          </Modal>
        )}
        {saveSuccessModal && (
          <Modal isModal={saveSuccessModal}>
            <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
              <div className="flex flex-col items-center justify-center h-full gap-[24px]">
                <p className="font-bold text-[18px]">요청 완료</p>
                <div>
                  <p className="text-[14px] font-bold">
                    숏폼 생성이 요청 되었습니다!
                  </p>
                  <p className="text-[14px] font-bold">
                    생성 현황은 <span className="text-main">‘사용 현황‘</span>{' '}
                    페이지에서 확인하세요.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex w-[400px]">
              <div className="w-[50%] bg-[#EEF1F7] h-[60px] border-b border-[#EEF1F7] rounded-bl-[40px] flex justify-center items-center">
                <button
                  className="text-[#5E6068] w-full font-bold"
                  onClick={() => {
                    navigate('/my-videos')
                  }}
                >
                  사용현황 페이지 가기
                </button>
              </div>
              <div className="w-[50%] bg-main h-[60px] border-b border-main rounded-br-[40px] flex justify-center items-center">
                <button
                  className="text-white w-full font-bold"
                  onClick={(event) => {
                    setSaveSuccessModal(false)
                    handleRemoveLink(event)
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          </Modal>
        )}
        {isModal && (
          <ConfirmPaymentCashModal
            isModal={isModal}
            setModal={setModal}
            amount={amountValue}
            bonusValue={bonusValue}
            valid={valid ? valid : ''}
            promotionCode={promotionCode ? Number(promotionCode) : 0}
          />
        )}

        {isImageTypeModal && (
          <ImageTypeModal
            isImageTypeModal={isImageTypeModal}
            setImageTypeModal={setImageTypeModal}
            userInfo={userInfo}
            imageList={templateList?.image}
            watch={watch}
            setValue={setValue}
            control={control}
          />
        )}

        {isMixTypeModal && (
          <MixTypeModal
            isMixTypeModal={isMixTypeModal}
            setMixTypeModal={setMixTypeModal}
            userInfo={userInfo}
            mixList={templateList?.mix}
            bgList={bgList}
            watch={watch}
            setValue={setValue}
            control={control}
          />
        )}
        {isVideoTypeModal && (
          <VideoTypeModal
            isVideoTypeModal={isVideoTypeModal}
            setVideoTypeModal={setVideoTypeModal}
            userInfo={userInfo}
            videoList={templateList?.clip}
            etcList={templateList?.etc}
            watch={watch}
            setValue={setValue}
            control={control}
          />
        )}
        {/* 캐시 사용 모달*/}
        {isUseModal && (
          <CashUseModal
            isUseModal={isUseModal}
            setUseModal={setUseModal}
            currentPoint={myPointData.totalPoint}
            shortVideoInfo={shortVideoInfo}
            shortVideoPriceInfo={shortVideoPriceInfo}
            setSaveSuccessModal={setSaveSuccessModal}
            setValue={setValue}
            getMyPointRefetch={getMyPointRefetch}
            setRequest={setRequest}
          />
        )}
        {isPending && (
          <>
            {' '}
            <Spinner />
          </>
        )}
      </div>
    </>
  )
}
